import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
    faHome,
    faDashboard,
    faAngleUp,
    faAngleDown,
    faAngleRight,
    faNavicon,
    faTimes,
    faCircle,
    faInfoCircle,
    faEye,
    faEyeSlash,
    faSpinner,
    faCalendar,
    faHistory,
    faMoneyCheck,
    faUser,
    faDownload,
    faCommentAlt,
    faCubes,
    faArrowLeft,
    faArrowRight,
    faSignIn,
    faWrench,
    faSave,
    faCheck,
    faCheckCircle,
    faSearch,
    faLandmark,
    faMoneyBillTransfer,
    faImage,
    faPaperPlane,
    faSignOut,
    faEdit,
    faPlus,
    faTrash,
    faUserTie,
    faUsersCog,
    faPlug,
    faLock,
    faPrint,
    faFile,
    faRefresh,
    faReply,
    faCopy,
    faMoneyBillTrendUp,
    faMoneyCheckDollar,
    faGear,
    faBox,
} from "@fortawesome/free-solid-svg-icons";

import {
    faFacebook,
    faInstagram,
    faYoutube,
    faTwitter,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons"

library.add(
    faHome,
    faDashboard,
    faAngleUp,
    faAngleDown,
    faAngleRight,
    faNavicon,
    faTimes,
    faCircle,
    faInfoCircle,
    faEye,
    faEyeSlash,
    faSpinner,
    faCalendar,
    faHistory,
    faMoneyCheck,
    faUser,
    faDownload,
    faCommentAlt,
    faCubes,
    faArrowLeft,
    faArrowRight,
    faSignIn,
    faWrench,
    faSave,
    faCheck,
    faCheckCircle,
    faSearch,
    faLandmark,
    faMoneyBillTransfer,
    faImage,
    faPaperPlane,
    faSignOut,
    faEdit,
    faPlus,
    faTrash,
    faUserTie,
    faUsersCog,
    faPlug,
    faLock,
    faPrint,
    faFacebook,
    faInstagram,
    faYoutube,
    faTwitter,
    faWhatsapp,
    faFile,
    faRefresh,
    faReply,
    faCopy,
    faMoneyBillTrendUp,
    faMoneyCheckDollar,
    faGear,
    faBox,
);

export default {
    install(app) {
        app.component("fa-icon", FontAwesomeIcon);
    }
}
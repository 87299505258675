import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {ROLE_BUSINESS} from "@/util/userRole";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {title: "Home"}
  },
  {
    path: '/register',
    name: 'Register',
    component: ()=> import('../views/RegisterView.vue'),
    meta: {title: "Buat Akun H2H"}
  },
  {
    path: '/registration-process/:phoneNumber',
    name: 'RegistrationProcess',
    component: ()=> import('../views/RegistrationProcessView.vue'),
    meta: {title: "Proses Pendaftaran"}
  },
  {
    path: '/login',
    name: 'Login',
    component: ()=> import('../views/LoginView.vue'),
    meta: {title: "Login"}
  },
  {
    path: '/partner',
    name: 'PartnerDashboard',
    component: ()=> import('../views/partner/DashboardView.vue'),
    meta: {
      guard: ROLE_BUSINESS
    },
    children: [
      {
        path: "",
        name: "PartnerHome",
        component: () => import("../views/partner/HomeView.vue"),
        meta: {
          title: "Dashboard",
          layout: {
            customMainContentsWrapper: true
          }
        }
      },
      {
        path: "setting/account",
        name: "PartnerAccount",
        component: () => import("../views/partner/AccountView.vue"),
        meta: {title: "Akun"}
      },
      {
        path: "transaction-report/check",
        name: "PartnerTransactionCheck",
        component: () => import("../views/partner/TransactionCheckView.vue"),
        meta: {title: "Cek Transaksi"}
      },
      {
        path: "transaction-report/history",
        name: "PartnerTransactionHistory",
        component: () => import("../views/partner/TransactionHistoryView.vue"),
        meta: {title: "Riwayat Transaksi"}
      },
      {
        path: "transaction-report/summary",
        name: "PartnerTransactionSummary",
        component: () => import("../views/partner/TransactionSummaryView.vue"),
        meta: {title: "Ringkasan Transaksi"}
      },
      {
        path: "product/status",
        name: "PartnerProductStatus",
        component: () => import("../views/partner/ProductStatusView.vue"),
        meta: {title: "Status Produk"}
      },
      {
        path: "product/list",
        name: "ProductList",
        component: () => import("../views/partner/ProductListView.vue"),
        meta: {title: "Daftar Produk"}
      },
      {
        path: "product/profit",
        name: "ProductProfit",
        component: () => import("../views/partner/ProductProfitView.vue"),
        meta: {title: "Profit Produk"}
      },
      {
        path: "balance/deposit-history",
        name: "PartnerDepositHistory",
        component: () => import("../views/partner/DepositHistoryView.vue"),
        meta: {title: "Riwayat Deposit"}
      },
      {
        path: "balance/debit-credit",
        name: "PartnerBalanceDebitCredit",
        component: () => import("../views/partner/BalanceDebitCreditView.vue"),
        meta: {title: "Balance Debit Credit"}
      },
      {
        path: "balance/topup",
        name: "PartnerTopup",
        component: () => import("../views/partner/TopupView.vue"),
        meta: {title: "Top Up"}
      },
      {
        path: "balance/bank-transfer-topup",
        name: "BankTransferTopup",
        component: () => import("../views/partner/BankTransferTopupView.vue"),
        meta: {title: "Konfirmasi Topup Manual"}
      },
      {
        path: "balance/auto-withdrawal",
        name: "BalanceAutoWithdrawal",
        component: () => import("../views/partner/BalanceAutoWithdrawalView.vue"),
        meta: {title: "Penarikan Otomatis"}
      },
      {
        path: "setting/log",
        name: "PartnerLog",
        component: () => import("../views/partner/LogView.vue"),
        meta: {title: "Log"}
      },
      {
        path: "setting/master-account",
        name: "PartnerMasterAccount",
        component: () => import("../views/partner/MasterAccountView.vue"),
        meta: {title: "Akun Induk"}
      },
      {
        path: "setting/user-management",
        name: "PartnerUserManagement",
        component: () => import("../views/partner/UserManagementView.vue"),
        meta: {title: "Manajemen Pengguna"}
      },
      {
        path: "transaction/refund",
        name: "RefundList",
        component: () => import("../views/partner/RefundListView.vue"),
        meta: {title: "Daftar Refund"}
      },
    ]
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ()=> import('../views/ContactUsView.vue'),
    meta: {title: "Hubungi Kami"}
  },
  {
    path: "/not-found",
    name: 'NotFound',
    component: ()=> import('../views/NotFoundView.vue'),
    meta: {title: "Not Found"}
  },
  { path: "/:catchAll(.*)", redirect: '/not-found' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
